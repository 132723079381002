import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Layout from '../../components/Layout'
import Banner from '../../components/Banner'
import BreadCrumbBlock from '../../components/Breadcrumb'
import Section from '../../components/Layout/Section'
import Title from '../../components/Layout/Title'
import { Container } from 'react-bootstrap'
import Filter from '../../components/Categories/Filter'
import Cate from '../../components/Categories/Categories'
import Tile from '../../components/Tile'
import Row from 'react-bootstrap/Row'
import Product from '../../models/product'
import { buildProductName, getProductUrlByCategory } from '../../helpers/theme'
import Col from 'react-bootstrap/Col'
import { Link } from 'gatsby'
import { formatPrice } from '../../helpers/price'
import 'string_score'
import { Audio, BallTriangle } from 'react-loader-spinner'

const Search = () => {
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(36)
  const [keyword, setKeyword] = useState('')
  const [cid, setCid] = useState(null)
  const [page, setPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [items, setItems] = useState([])
  const [filterValues, setFilterValues] = useState({
    limit: 'all',
    sort: 'score',
  })
  const [allProducts, setAllProducts] = useState([])

  useEffect(() => {
    fetch('/products.json')
      .then(res => res.json())
      .then(res => {
        setAllProducts(res)
      })
  }, [])
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search)
      setKeyword(searchParams.get('q'))
      if (searchParams.has('page')) {
        const pg = parseInt(searchParams.get('page'), 10)
        if (!isNaN(pg)) setPage(pg)
        else setPage(1)
      } else setPage(1)

      setCid(searchParams.get('cid'))
    }
  }, [window.location.search])

  function loadMore() {
    setVisible(visible + 36)
  }

  function loadMore() {
    setVisible(visible + 36)
  }

  const getScore = useCallback((t, k) => {
    const _lowerT = t?.toLowerCase()
    const _lowerK = k?.toLowerCase()
    return _lowerT?.includes(_lowerK) ? _lowerT?.score(_lowerK) : 0
  }, [])

  const getScoreCode = useCallback((t, k) => {
    return t === k;
  }, [])

  const getVisibility = useCallback((t) => {
    if (t==="Catalog" || t==="Not Visible Individually"){
      return 0;
    }
    return 1;
  }, [])

  const filteredItems = useMemo(() => {
    const filteredProducts = allProducts
      .map(p => ({
        ...p,
        nameScore: getScore(p.name, keyword),
        skuScore: getScore(p.sku, keyword),
        orderCodeScore: getScoreCode(p.order_code, keyword),
        visibilityScore: getVisibility(p.visibility_text),
        shortDescriptionScore: getScore(p.short_description.html, keyword),
        LongDescriptionScore: getScore(p.description.html, keyword),
      }))
      .filter(
        p =>
          p.nameScore > 0 ||
          p.skuScore > 0 ||
          p.orderCodeScore > 0 ||
          p.shortDescriptionScore > 0 ||
          p.LongDescriptionScore > 0
      ).filter(
          p=>
              p.visibilityScore > 0
        )

    setTotalItems(filteredProducts.length)

    filteredProducts.sort((a, b) => {
      switch (filterValues.sort) {
        case 'score':
          return (
            b.nameScore - a.nameScore ||
            b.skuScore - a.skuScore ||
            b.orderCodeScore - a.orderCodeScore
          )
        case 'az':
          return a.name.localeCompare(b.name)
        case 'za':
          return b.name.localeCompare(a.name)
        case 'lowhigh':
          return (
            a.price_range.minimum_price.final_price.value -
            b.price_range.minimum_price.final_price.value
          )
        case 'highlow':
          return (
            b.price_range.minimum_price.final_price.value -
            a.price_range.minimum_price.final_price.value
          )
      }
      return 0
    })

    if (filterValues.limit != 'all') {
      return filteredProducts
    }

    // const startIndex = (page - 1) * filterValues.limit
    // const endIndex = page * filterValues.limit
    // return filteredProducts.slice(startIndex, endIndex)

    setItems(filteredProducts)
  }, [allProducts, keyword, page, filterValues.limit, filterValues.sort, cid])
  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    const loadData = async () => {
      // Wait for two second
      await filteredItems

      // Toggle loading state
      setLoading(loading => !loading)
    }

    loadData()
  }, [])

  if (loading) {
    return (
      <Layout>
        <BreadCrumbBlock
          items={[]}
          current={{ path: null, name: `Search results for ${keyword}` }}
        />
        <div className="loading-box">
          <BallTriangle
            height="100"
            width="100"
            color="black"
            ariaLabel="loading"
          />
        </div>
      </Layout>
    )
  } else {
    return (
      <Layout>

        <BreadCrumbBlock
          items={[]}
          current={{ path: null, name: `Search results for ${keyword}` }}
        />
        <Section noSpacingTop hasDivider>
          <Container>
            <Cate
              category={{}}
              items={items}
              pageInfo={{}}
              visible={visible}
              onPaginationItemClick={{}}
            />

            {visible < items.length && (
              <button
                className="btn btn-outline-primary mx-auto d-flex"
                onClick={loadMore}
              >
                Show More Products
              </button>
            )}
          </Container>
        </Section>

        {/*<Section>*/}
        {/*  <Tile />*/}
        {/*</Section>*/}
      </Layout>
    )
  }
}

export default Search
